import React from 'react';
import { DateRange } from 'react-date-range';

import './datePicker.css';

import { format } from 'date-fns';
// import {disableDay, findDate} from '../../helpers/dateHelpers';

// type defaultBookingRange = {
//     startDate: Date,
//     endDate: Date,
//     key: string
// };
  

function DatePicker({blockedDates, selectionHandler, selectedDates, availableDates}) {

  const dates = availableDates;

  const findDate = (day) => {
    return dates[format(day, 'dd.MM.yyyy')];
  };
  
  const disableDay = (day) => {
    const date = findDate(day);
    return !date || date.type === 'unavailable';
  };

  function customDayContent(day) {
    const date = findDate(day);
    return (
      <>
        <div className={date ? date.type : "unavailable"}>
        </div>
        <span className='d' >{format(day, "d")}</span>
      </>
    );
  }

  return (
      <DateRange
        wrapperClassName="react_datepicker_custom_styles"
        editableDateInputs={true}
        minDate={new Date()}
        disabledDates={blockedDates}
        onChange={selected => selectionHandler(selected.booking)}
        moveRangeOnFirstSelection={false}
        ranges={[selectedDates]}
        dragSelectionEnabled={false}
        dayContentRenderer={customDayContent}
        disabledDay={disableDay}
        retainEndDateOnFirstSelection={false}
        fixedHeight={true}
      // scroll={{enabled: true}}
      />
  );
}

export default DatePicker;