import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import Lines1 from '../../../assets/images/form/pageBackgrounds/lines1.svg';
import Lines2 from '../../../assets/images/form/pageBackgrounds/lines2.svg';
import Lines3 from '../../../assets/images/form/pageBackgrounds/lines3.svg';

export const pages = {
    0: {
        background: Lines1,
        contents: StepOne,
    },
    1: {
        background: Lines1,
        contents: StepTwo,
    },
    2: {
        background: Lines2,
        contents: StepThree,
    },
    3: {
        background: Lines3,
        contents: StepFour
    },
};

export default pages;