import React from "react";
import styled from "styled-components";

import { COLORS } from "../../Constants/variables";

const TagContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1.5px ${COLORS.tagColor} solid;
    border-radius: 5px;
    padding: 5px 10px;
`;
const IconContainer = styled.div`
    background-image: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: center;
    // background-size: cover;
    width: 25px;
    height: 20px;
`;

const TagTextContainer = styled.div`
white-space: nowrap;
`;

const Tag = ({icon, text}) => {
    return (
        <TagContainer>
            <IconContainer icon={icon} />
            <TagTextContainer>
                {text}
            </TagTextContainer>
        </TagContainer>
    );
};

export default Tag;