import { addDays as fnsAddDays, format, eachDayOfInterval, intervalToDuration } from 'date-fns';

export const addDays = fnsAddDays;

export const getDateStringFromTimestamp = (timestamp) => format(new Date(timestamp).getTime(), 'iiii, dd MMMM h:mm');

export const dateToTimestamp = (date) => {
    const stringified = new Date(date).getTime();
    return stringified;
};

export const formatDate = (date) => format(date, 'dd.MM.yyyy');

export const formatDateWeb = (date) => format(new Date(date), 'yyyy-MM-dd');

export const getTomorrowDate = () => addDays(new Date(), 1);

export const findDate = (dates, day) => dates[formatDate(day)];

export const disableDay = (day) => {
    const date = findDate(day);
    return !date || date.type === 'unavailable';
};

export const formatRange = (range) => {
    return {
        startDate: new Date(range.startDate).getTime(),
        endDate: new Date(range.endDate).getTime(),
        key: 'booking'
    };
};

export const calcPrice = (range, dates) => {
    let total = 0;
    range = formatRange(range);
    const days = eachDayOfInterval({ start: range.startDate, end: range.endDate });
    days.splice(-1);
    days.forEach((day) => total += findDate(dates, day).price);
    return total;
};

export const isDateUnavailable = (date) => !date || date.type === 'unavailable' || date.type === 'endAvailable';
export const isSameDate = (range, currentRange) => {
    const startDate = range.startDate.getTime()
    const endDate = range.endDate.getTime();
    if (!(startDate === endDate)) return false;
    return true;
}

export const setRangeHours = (range) => {
    range.startDate.setHours(0, 0, 0, 0);
    range.endDate.setHours(0, 0, 0, 0);
    return range;
};

export const isRangeBookable = (daysAvailability) => (daysAvailability.every(isDayAvailable => isDayAvailable === false))

export const checkDateRange = (range, currentRange, dates) => {

    range = setRangeHours(range);

    if (!isSameDate(range, currentRange)) {
        const days = eachDayOfInterval({ start: range.startDate, end: range.endDate });
        const daysAvailability = days.map(day => isDateUnavailable(dates[formatDate(day.getTime())]));
        daysAvailability.splice(-1);
        if ((daysAvailability.every(isDayAvailable => isDayAvailable === false))) return true;
    }

    return false;
};

export const getIntervalLength = (range) => intervalToDuration({
    start: new Date(range.startDate),
    end: new Date(range.endDate)
}).days;