import React from 'react';
import styled from 'styled-components';


const CheckDetailsContainer = styled.div`
width: 100%;
`;

const CheckDetailsLink = styled.div`
font-size: 1.2em;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
text-align: center;
text-decoration: underline;
margin-top: 20px;
pointer-events: all;
cursor: pointer;
`;

const CheckDetailsWrapper = ({linkText, children, linkHandler}) => {
    return (
        <CheckDetailsContainer>
            {children}
            { linkText && linkHandler && <CheckDetailsLink onClick={linkHandler}>{linkText} </CheckDetailsLink> }
        </CheckDetailsContainer>
    );
}

export default CheckDetailsWrapper;