import { TEXTS } from "../../../../Constants/variables";

import iconBedroom from '../../../../assets/images/form/tag_icons/bedroom.svg';
import iconBed from '../../../../assets/images/form/tag_icons/beds.svg';
import iconFire from '../../../../assets/images/form/tag_icons/fire.svg';
import iconHelipad from '../../../../assets/images/form/tag_icons/helipad.svg';
import iconJacuzzi from '../../../../assets/images/form/tag_icons/jacuzzi.svg';
import iconKitchen from '../../../../assets/images/form/tag_icons/kitchen.svg';
import iconPool from '../../../../assets/images/form/tag_icons/pool.svg';
import iconSunchair from '../../../../assets/images/form/tag_icons/sunchair.svg';
import iconToilet from '../../../../assets/images/form/tag_icons/toilet.svg';
import iconBathroom from '../../../../assets/images/form/tag_icons/bathroom.svg';

export const tagData = {
    bedrooms: {
        text: TEXTS.formPages.tags.bedrooms,
        icon: iconBedroom,
    },
    beds: {
        text: TEXTS.formPages.tags.beds,
        icon: iconBed,
    },
    kitchen: {
        text: TEXTS.formPages.tags.kitchen,
        icon: iconKitchen,
    },
    bathrooms: {
        text: TEXTS.formPages.tags.bathrooms,
        icon: iconBathroom,
    },
    toilets: {
        text: TEXTS.formPages.tags.toilets,
        icon: iconToilet,
    },
    jacuzzi: {
        text: TEXTS.formPages.tags.jacuzzi,
        icon: iconJacuzzi,
    },
    sunchairs: {
        text: TEXTS.formPages.tags.sunchairs,
        icon: iconSunchair,
    },
    helipad: {
        text: TEXTS.formPages.tags.helipad,
        icon: iconHelipad,
    },
    fire: {
        text: TEXTS.formPages.tags.fire,
        icon: iconFire,
    },
    pool: {
        text: TEXTS.formPages.tags.pool,
        icon: iconPool,
    },
}