import React from "react";
import styled from "styled-components";
import { TEXTS, COLORS } from "../../../../../Constants/variables";
import { currencyRates } from "../../../../../Constants/settings";
import { getTomorrowDate } from "../../../../../helpers/dateHelpers";
import { HeadingTextContainer } from "../../styles/stepOne";
import TextMarkBlock from "../TextMarkBlock";

const PaymentInfoContainer = styled.div``;

const PaymentInfoRowSmall = styled.div`
  font-size: 1em;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.primaryGold};
  opacity: 0.5;
`;

const PaymentInfoRowBig = styled.div`
  color: ${COLORS.primaryGold};
  font-size: 3em;
  font-weight: 500;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
`;

const PayBeforeRow = styled.div`
  font-size: 0.8em;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.primaryGold};
`;

const PaymentDetailsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PaymentInfo = ({ price }) => {
  const payBeforeDate = new Date();
  payBeforeDate.setMinutes(payBeforeDate.getMinutes() + 10);
  const formattedPayBeforeDate = payBeforeDate.toLocaleString();

  return (
    <PaymentInfoContainer>
      <HeadingTextContainer>
        {TEXTS.formPages.page3.paymentText}
      </HeadingTextContainer>
      <PaymentDetailsContainer>
        <PaymentInfoRowBig>
          {price > 0 ? `$ ${price.toLocaleString()}` : 0}
        </PaymentInfoRowBig>
        <PaymentInfoRowSmall>
          {price > 0
            ? `${(price * currencyRates.idr).toLocaleString()} IDR`
            : 0}
        </PaymentInfoRowSmall>

        <PayBeforeRow>{`Pay before ${formattedPayBeforeDate}`}</PayBeforeRow>
      </PaymentDetailsContainer>
      <TextMarkBlock>{TEXTS.formPages.page3.refund}</TextMarkBlock>
    </PaymentInfoContainer>
  );
};

export default PaymentInfo;
