import React, { useMemo } from 'react';
import styled from 'styled-components';

import CheckDetailsWrapper from '../CheckDetailsWrapper';
import TextMarkBlock from '../TextMarkBlock/index.js';

import DateDots from '../../../../../assets/images/form/date_dots.svg';

import { TEXTS } from '../../../../../Constants/variables';
import { HeadingTextContainer } from '../../styles/stepOne';
import { getDateStringFromTimestamp } from '../../../../../helpers/dateHelpers';

const ReservationDetailsContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 20px;
`;

const CheckInOutDatesContainer = styled.div`
display: grid;
grid-template-columns: repeat(4, 1fr);
grid-template-rows: repeat(5, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px; 
`;

const DateDotsContainer = styled.div`
grid-area: 1 / 1 / 5 / 2;
background-image: url(${DateDots});
background-size: cover;
background-size: 20px;
background-repeat: no-repeat;
background-position: center;
`;

const CheckInText = styled.div`
grid-area: 1 / 2 / 2 / 6;
`;

const CheckInDate = styled.p`
grid-area: 2 / 2 / 3 / 6;
margin: 0;
`;

const CheckOutText = styled.div`
grid-area: 4 / 2 / 5 / 6;
`;

const CheckOutDate = styled.p`
grid-area: 5 / 2 / 6 / 6; 
margin: 0;
`;

// const formatDatesToString = (dates) => ({
//     startDate: getDateStringFromTimestamp(dates.startDate),
//     endDate: getDateStringFromTimestamp(dates.endDate),
// });

const CheckDates = ({ dates, linkText, bookingCode, linkHandler }) => {

    return (
        <ReservationDetailsContainer>
            <HeadingTextContainer >
                {TEXTS.formPages.page2.headingTop}
            </HeadingTextContainer >
            <CheckDetailsWrapper linkText={linkText} linkHandler={linkHandler}>
                {bookingCode && <TextMarkBlock>
                    {`${TEXTS.formPages.page2.bookingCodeText} ${bookingCode}`}
                </TextMarkBlock>}
                <CheckInOutDatesContainer>
                    <DateDotsContainer />
                    <CheckInText>
                        Check In:
                    </CheckInText>
                    <CheckInDate>
                        {getDateStringFromTimestamp(dates.startDate)}
                    </CheckInDate>
                    <CheckOutText>
                        Check Out:
                    </CheckOutText>
                    <CheckOutDate>
                        {getDateStringFromTimestamp(dates.endDate)}
                    </CheckOutDate>
                </CheckInOutDatesContainer>
            </CheckDetailsWrapper>
        </ReservationDetailsContainer>
    );
}

export default CheckDates;