import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PageContainer, HeadingContainer, HeadingTextContainer, DescriptionContainer, DatePickerBlockContainer, DatePickerContainer, SecondHeadingContainer, FooterContainer, TotalPriceContainer, TotalPriceText, TotalPriceValue, ButtonContainer, DatePickerStyled, HeadingPartContainer, DescriptionPartContainer, DescriptionPartContainerBold } from './styles/stepOne.js';
import { useGetDatesQuery } from "../../../api/index";
import { setDates, setPrice } from '../../../store/bookingSlice.js';
import { setInitLoad } from '../../../store/settingsSlince.js';
import { addDays, calcPrice, checkDateRange, dateToTimestamp, isSameDate, findDate, isDateUnavailable } from '../../../helpers/dateHelpers.js'
import Button from '../../../Components/Button/index.js';

import { TEXTS } from '../../../Constants/variables.js';

const localTexts = TEXTS.formPages.page1;

const initSelDateState = {
    startDate: new Date(),
    endDate: addDays(new Date(), 1),
    key: 'booking'
};

const StepOne = ({nextStep, prevStep}) => {

    const dispatch = useDispatch();

    const isLoadedOnce = useSelector((state) => state.settings._INIT);

    const [selectedDates, setSelectedDates] = useState(initSelDateState);
    const [availableDates, setAvailableDates] = useState({});
    const [price, setBookingPrice] = useState(0);

    const { data: dateData, error, isFetching } = useGetDatesQuery(isLoadedOnce, {
        // skip: isLoadedOnce,
    });

    useEffect(() => {
        if (dateData) dispatch(setInitLoad());
        setAvailableDates(dateData);
    }, [dateData, dispatch]);

    useEffect(() => {
        //Calculating price from initial date selection
        if (availableDates && JSON.stringify(availableDates) !== "{}") {
            const initPrice = calcPrice(initSelDateState, availableDates);
            setBookingPrice(initPrice);
        }
    }, [availableDates])

    useEffect(() => {
        //Referral link ?
        const queryParams = new URLSearchParams(window.location.search);
        let refParam = queryParams.get('utm_source');
        if(queryParams.get("fbclid")){ refParam = "fbclid" };
        if (refParam) {
            localStorage.setItem('ref', refParam);
        }
    }, []);

    const selectionHandler = async (selectedRange) => {
        if (isSameDate(selectedRange)) {
            setSelectedDates({...selectedRange});
            setBookingPrice(0);
    }
        else if (checkDateRange(selectedRange, selectedDates, availableDates)) {      
            const price = calcPrice(selectedRange, availableDates);
            await setSelectedDates({...selectedRange});
            await setBookingPrice(price);
        }
        
    };

    const handleSubmitDateSelection = () => {
        if (selectedDates.startDate && selectedDates.endDate && price > 0) {
            dispatch(setDates({
                startDate: dateToTimestamp(selectedDates.startDate),
                endDate: dateToTimestamp(selectedDates.endDate),
                key: 'booking'
            }));
            dispatch(setPrice(price));
            nextStep();
        }
    };

    return (
        <PageContainer>
            <HeadingContainer>
                <HeadingTextContainer>
                    <HeadingPartContainer>{localTexts.headingTopPt1}</HeadingPartContainer>
                    <HeadingPartContainer>{localTexts.headingTopPt2}</HeadingPartContainer>
                </HeadingTextContainer>
                <DescriptionContainer >
                    <DescriptionPartContainer>{localTexts.descriptionPt1}</DescriptionPartContainer>
                    <DescriptionPartContainerBold>{localTexts.descriptionPt2Bold}</DescriptionPartContainerBold>
                    <DescriptionPartContainer>{localTexts.descriptionPt3}</DescriptionPartContainer>
                </DescriptionContainer >
            </HeadingContainer>
            <DatePickerBlockContainer>
                <SecondHeadingContainer>
                    {localTexts.CalendarHeading}
                </SecondHeadingContainer>
                {isFetching && <div>{TEXTS.formPages.loadingText}</div>}
                {error && <div>{TEXTS.formPages.errorLoading}</div>}
                {availableDates &&
                    <DatePickerContainer>
                        <DatePickerStyled
                            blockedDates={[]}
                            selectionHandler={selectionHandler}
                            availableDates={availableDates}
                            selectedDates={selectedDates}
                        />
                    </ DatePickerContainer>
                }
            </DatePickerBlockContainer>
            <FooterContainer>
                <TotalPriceContainer>
                    <TotalPriceText>
                        {localTexts.PriceText}
                    </TotalPriceText>
                    <TotalPriceValue>
                        {`$ ${price}`}
                    </TotalPriceValue>
                </TotalPriceContainer>
                <ButtonContainer>
                    <Button id='firstButton' disabled={!(price > 0)} buttonText={localTexts.ButtonText} clickHandler={handleSubmitDateSelection} />
                </ButtonContainer>
            </FooterContainer>
        </ PageContainer>
    );
}

export default StepOne;