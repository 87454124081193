import React from 'react';
import styled from 'styled-components';

import {ReactComponent as Logo} from '../../../assets/images/logo_with_title.svg';

import { COLORS } from "../../../Constants/variables";

const SvgBackgroundWrapperContainer = styled.div`
background-image: url(${(props) => props.background});
background-size: cover;
// background-repeat: no-repeat;
background-color: ${COLORS.primaryBackground};
width: 100%;
padding: 40px 30px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
// border: .6px gray solid;
#container svg path {
    fill: ${COLORS.primaryButtonBackground};
    opacity: 0.1;
}
}
`;

const LogoContainer = styled.div`
display: flex;
flex-direction: row;
width: 75%;
align-items: center;
  justify-content: center;
`;

const SvgBackgroundWrapper = ({background, children}) => {
    return (
        <SvgBackgroundWrapperContainer background={background}>
        <LogoContainer>
            <Logo/>
        </LogoContainer>
      {children}
    </SvgBackgroundWrapperContainer>
    );
}

export default SvgBackgroundWrapper;