import React from 'react';
import styled from 'styled-components';

import {COLORS, TYPOGRAPHY } from '../../Constants/variables';

const ButtonContainer = styled.button`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 10px;
border-radius: 100px;
border: none;
box-shadow: none;
background-color: ${({ $background, disabled }) => disabled ? COLORS.transparent : $background ? $background : COLORS.primaryButtonBackground};
color: ${({ $color, $disabled }) => $disabled ? COLORS.primaryFont : $color ? $color : COLORS.primaryWhite};
border: 1px ${({ $borderColor, $disabled }) => $disabled ? COLORS.primaryFont : $borderColor ? $borderColor : 'unset'} solid;
font-family: ${TYPOGRAPHY.primaryFontType};
font-size: 1em;
font-weight: 700;
// display:inline-block;
box-sizing: border-box;
text-decoration:none;
text-align:center;
transition: all 0.2s;
padding:0.6em 3.2em;
width: 100%;
white-space: nowrap;
&:hover {
    transform: ${({ $disabled }) => $disabled ? 'none' : 'scale(1.05)'};
}
pointer-events: all;
`;

const IconContainer = styled.div`
background-image: url(${({ icon }) => icon});
background-repeat: no-repeat;
background-position: center;
// background-size: cover;
height: 20px;
width: 20px;
`;

const Button = ({id, buttonText, icon, background, borderColor, clickHandler, color, disabled}) => {
    return (
        <ButtonContainer id={id} disabled={disabled} $disabled={disabled} onClick={clickHandler} $background={background} $borderColor={borderColor} $color={color}>
            {icon && <IconContainer icon={icon}/>}
            {buttonText}
        </ButtonContainer>
    );
}

export default Button;