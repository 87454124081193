import { createSlice } from '@reduxjs/toolkit';
import { addDays } from '../helpers/dateHelpers';

//Convering dates to timestamps and back for keeping in store

const initialState = {
  range: {
    startDate: new Date().getTime(),
    endDate: addDays(new Date(), 1).getTime(),
  },
  price: 0,
  availableDates: [],
  guestDetails: {
    name: '',
    email: '',
    phone: '',
    additionalRequest: '',
  },
  bookingCode: '',
  referralCode: '',
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setDates(state, action) {
      state.range = action.payload;
    },
    setPrice(state, action) {
      state.price = action.payload;
    },
    setAvailableDates(state, action) {
      state.availableDates = action.payload;
    },
    setName(state, action) {
      state.guestDetails.name = action.payload;
    },
    setEmail(state, action) {
      state.guestDetails.email = action.payload;
    },
    setPhone(state, action) {
      state.guestDetails.phone = action.payload;
    },
    setAdditionalRequest(state, action) {
      state.guestDetails.additionalRequest = action.payload;
    },
    setBookingCode(state, action) {
      state.bookingCode = action.payload;
    },
    setGuestDetails(state, action) {
      state.guestDetails = {
        name: action.payload.name,
        email: action.payload.email,
        phone: action.payload.phone,
        additionalRequest: action.payload.additionalRequest,
      };
    },
    setReferralCode(state, action) {
      state.guestDetails.referralCode = action.payload;
    },
  },
});

export const { setDates, setAvailableDates, setName, setEmail, setPhone, setAdditionalRequest, setBookingCode, setPrice, setGuestDetails, setReferralCode } = bookingSlice.actions
export default bookingSlice.reducer