import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useProceedPaymentMutation } from "../../../api/index";

import { PageContainer, HeadingTextContainer } from './styles/stepOne';
import {AirbnbButton, BookingcomButton, CreditCardButton, CallManagerButton, PaypalButton} from './components/Buttons'
import PaymentInfo from './components/PaymentInfo/index.js';

import { TEXTS, COLORS } from '../../../Constants/variables';


const PayMenthodHeadingConatainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-bottom: 20px;
`

const PayMenthodButtonsContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 10px;
width: 100%;
margin-top: 20px;
`;


const StepFour = ({nextStep, prevStep}) => {

    const { price, bookingCode, range, guestDetails} = useSelector(state => state.booking);

    const [ proceedPayment, response ] = useProceedPaymentMutation();

    const handleClickPayment = () => {
        proceedPayment({
            code: bookingCode,
            status: 'pending',
        })
        .then(response => {
            console.log(response);
            const ivoiceRedirectUrl = response?.data?.invoice
            if (ivoiceRedirectUrl) window.location.href = ivoiceRedirectUrl;
        })
        .then((error) => console.log(error))
    };

    return (
        <PageContainer>
               <PaymentInfo price={price}/>
            <PayMenthodButtonsContainer>
                <PayMenthodHeadingConatainer>
                    {/* <HeadingTextContainer>
                        {TEXTS.formPages.page3.payMethod}
                    </HeadingTextContainer> */}
                </PayMenthodHeadingConatainer>
                <CreditCardButton clickHandler={handleClickPayment}/>
                {/* <PaypalButton /> */}
                {/* <BookingcomButton bookingRange={range}/> */}
                {/*<AirbnbButton bookingRange={range}/>*/}
                <CallManagerButton booking={{bookingCode, range, additionalRequest: guestDetails.additionalRequest}}/>
            </PayMenthodButtonsContainer>
            {/* {isFetching && <div>{TEXTS.formPages.loadingText}</div>}
            {error && <div>{TEXTS.formPages.errorProceedingInvoice}</div>} */}
        </PageContainer>
    );
}

export default StepFour;
