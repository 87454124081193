import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Alert } from 'react-bootstrap';
import FormWizard from '../../Components/FormWizard';
import { PageContainer, HeadingTextContainer } from '../BookingForm/Steps/styles/stepOne';
import { SecondHeadingContainer } from '../BookingForm/Steps/styles/stepOne';
import CheckDates from '../BookingForm/Steps/components/CheckDates';
import { CallManagerButton } from '../BookingForm/Steps/components/Buttons';
import { useLocation } from 'react-router-dom';
import { useGetBookingByCodeQuery } from "../../api";

import { TEXTS } from '../../Constants/variables';

import Lines1 from '../../assets/images/form/pageBackgrounds/lines1.svg';

const initRangeState = {
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
}

const BookingConfirmationContainer = styled.div`
// display: flex;
// flex-direction: column;
// align-items: center;
// justify-content: center;
// gap: 30px;
margin: 20px 5px;
width: 100%;
padding: 0 10px;
width: 500px;
@media (max-width: 420px) {
    width: 100%;
    padding: 0 10px;

`;

const ReservationDetailsContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const ReservationDetailRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: left;
`;

const ReservationDetailName = styled.div`
`;

const ReservationDetailValue = styled.div`
margin-left: 5px;
`;


const ConfirmationForm = () => {

    const [ isBookingChecked, setIsBookingChecked ] = useState(false);
    const [ bookingInfo, setBookingInfo ] = useState({
        name: "",
        email: "",
        phone: "",
        additionalRequest: "",
        bookingCode: "",
        price: "",
        range: initRangeState,
        comment: "",
    });

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const bookingStatus = queryParams.get('status');

    const { data: bookingData, error, isFetching } = useGetBookingByCodeQuery(code, {
        skip: isBookingChecked,
    });
    if (code && bookingData) {
        const bookingInfo = {
            name: bookingData?.client.name,
            email: bookingData?.client.email,
            phone: bookingData?.client.phone,
            additionalRequest: bookingData?.comment,
            bookingCode: bookingData?.code,
            price: bookingData?.price,
            range: {
                startDate: bookingData?.startDate,
                endDate: bookingData?.endDate,
            },
        };
        setBookingInfo(bookingInfo);
        setIsBookingChecked(true);
    };


    return (
        <PageContainer>
            <Alert style={{width: '100%'}} key={bookingStatus === 'success' ? 'success' : 'warning'} variant={bookingStatus === 'success' ? 'success' : 'warning'} >
                {bookingStatus === 'success' ? 'Booking success' : 'Booking failed'}
            </Alert>
            <SecondHeadingContainer style={{ margin: 0 }}>{TEXTS.formPages.page3.headingTop}</SecondHeadingContainer>
            <ReservationDetailsContainer>
                {['name', 'email', 'phone', 'additionalRequest'].map(value => (
                    <ReservationDetailRow key={`reservation_details_row-${value}`}>
                        <ReservationDetailName>{`${TEXTS.formPages.confirmationPage[value]}: `}</ReservationDetailName>
                        <ReservationDetailValue>{bookingInfo[value]}</ReservationDetailValue>
                    </ReservationDetailRow>
                ))}
            </ReservationDetailsContainer>
            <CheckDates dates={bookingInfo.range} bookingCode={bookingInfo.bookingCode} />
           < CallManagerButton/>
        </PageContainer>
    )
};

export const BookingConfirmation = () => {

    return (
        <BookingConfirmationContainer>
            <FormWizard steps={{
                0: {
                    background: Lines1,
                    contents: ConfirmationForm,
                },
            }} />
        </BookingConfirmationContainer>
    )
}

export default BookingConfirmation;