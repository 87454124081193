import styled from 'styled-components';
import Cross from '../../assets/images/form/close_cross.svg'

import { COLORS } from '../../Constants/variables';

export const FormWizardConatainer = styled.div`
display: flex;
flex-direction: column;
background-color: ${COLORS.backgroundBrown};
justify-content: center;
align-items: center;
// max-width: 600px;
// min-width: 50vw;
height: min-content;
border-radius: 38px;
overflow: hidden;
border: .6px gray solid;
`;
export const FormWizrdHeader = styled.div`
background-color: inherit;
display: flex;
flex-directon: row;
align-items: center;
width: 100%;
height: 80px;
padding: 15px 30px;
justify-content: space-between;
`;

export const FormWizrdContentContainer = styled.div`
background-color: inherit;
display: flex;
width: 100%;
flex-direction: column;
// border-radius: 38px;
overflow: hidden;
. 
`;

export const PageCounter = styled.div`
height: 100%
    display: flex;
    flex-directon: row;
    align-items: center;
justify-content: center;
display: flex;
  flex-direction: row;
  gap: 10px;
  color: ${COLORS.primaryWhite}
`;

export const CloseCircle = styled.div`
height: 100%;
width: auto;
aspect-ratio: 1 / 1;
display: flex;
flex-directon: row;
align-items: center;
justify-content: center;
margin-left: auto;
border-radius: 100%;
background-image: url(${Cross});
background-size: 14px;
background-repeat: no-repeat;
background-position: center center;
background-color: ${COLORS.primaryWhite};
pointer-events: all;
cursor: pointer;
&:hover {
    background-size: 16px;
}
`;


export const StepText = styled.p`
margin-bottom: 0;
font-size: 16px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
text-align: center;

`;

export const StepsLeft = styled.p`
margin-bottom: 0;
font-size: 24px;
font-weight: 700;
line-height: 33px;
letter-spacing: 0em;
text-align: center;
`;
