export const settings = {
    'development': {
        baseURL: 'http://localhost:3000/api/',
    },
    'production': {
        baseURL: 'https://secure.privatejetvilla.com/api',
    }
};

export const links = {
    booking: 'https://secure.booking.com/book.html?',
    airbnb: 'https://www.airbnb.com/book/stays/1016472629029495038?',
    paypal: 'http://localhost:3000',
    whatsapp: 'https://wa.me/6287784070409',
    mainLanding: 'https://privatejetvilla.com/',
};

export const currencyRates = {
    idr: 15722,
}

export default settings;