import styled from 'styled-components';
import DatePicker from '../../../../Components/DatePicker';

import { TYPOGRAPHY } from '../../../../Constants/variables';

export const PageContainer = styled.div`
font-family: ${TYPOGRAPHY.primaryFontType};
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-top: 50px;
width: 100%;
gap: 30px;
}
`;

export const HeadingContainer = styled.div`
display: flex;
text-align: center;
flex-direction: column;
align-items: center;
gap: 30px;
width: 100%;
}
`;

export const HeadingTextContainer = styled.div`
font-size: 2em;
font-weight: ${TYPOGRAPHY.topHeading.topHeadingFontWeight};
line-height: .8em;
text-align: center;
text-transform: capitalize;
}
`;

export const HeadingPartContainer = styled.p`
margin: 0;
`;

export const DescriptionPartContainer = styled.p`
margin: 0;
display: inline;
`;

export const DescriptionPartContainerBold = styled.p`
margin: 0;
display: inline;
font-weight: 700;
`;

export const DescriptionContainer = styled.div`
font-family: Manrope;
font-size: 1.2em;
font-weight: 300;
letter-spacing: 0em;
text-align: center;
`;

export const DatePickerBlockContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;

`;

export const DatePickerContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
`;

export const DatePickerStyled = styled(DatePicker)`
width: auto;
display: contents;
`;

export const SecondHeadingContainer = styled.div`
font-size: 3em;
margin: 20px 0 0 0;
font-weight: 500;
white-space: nowrap;
`;

export const FooterContainer = styled.div`
width: 100%;
display: flex;
align-content: center;
justify-content: space-between;
align-items: center;
gap: 30px;
padding: 0 5px;
`;

export const TotalPriceContainer = styled.div`
width: min-content;
display: flex;
flex-direction: column;
justify-content: space-between;
white-space: nowrap;
`;

export const TotalPriceText = styled.div`
font-size: 16px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
`;

export const TotalPriceValue = styled.div`
font-size: 24px;
font-weight: 700;
line-height: 33px;
letter-spacing: 0em;
text-align: left;

`;

export const ButtonContainer = styled.div`
min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
