import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  _INIT: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setInitLoad(state, action) {
      state._INIT = true;
    },
  },
});

export const { setInitLoad } = settingsSlice.actions
export default settingsSlice.reducer