import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import 'react-international-phone/style.css';

import {PageContainer, SecondHeadingContainer } from './styles/stepOne.js';
import Button from '../../../Components/Button/index.js';
import CheckDates from './components/CheckDates';
import { Form } from 'react-bootstrap';
import { PhoneInput } from 'react-international-phone';
import { setBookingCode, setGuestDetails } from '../../../store/bookingSlice.js';

import { usePostBookingMutation } from "../../../api/index";

import { TEXTS } from '../../../Constants/variables';
import { formatDate } from '../../../helpers/dateHelpers.js';

const GuestDetailsFormContainer = styled.div`
`;
const GuestDetailsInputContainer = styled.div`
width: 100%;
`;

const CheckDatesContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;


const StepTwo = ({nextStep, prevStep}) => {

    const dispatch = useDispatch();

    const [addBooking, response] = usePostBookingMutation({
        ignoreResults: true,
    });

    const { price } = useSelector(state => state.booking.price);
    const selectedDates = useSelector(state => state.booking.range);
    
    const formRef = useRef(null);

    //Form field values by ref to avoid excessive rerenders
    const nameInputRef = useRef(null);
    const emailInputRef = useRef(null);
    const phoneInputRef = useRef(null);
    const commentInputRef = useRef(null);

    const createBooking = (guestDetails) => {
        const booking = {
          client: {
            name: guestDetails.name,
            email: guestDetails.email,
            phone: guestDetails.phone,
          },
          startDate: formatDate(selectedDates.startDate),
          endDate: formatDate(selectedDates.endDate),
          comment: guestDetails.additionalRequest,
          price: price
        }

        const ref = localStorage.getItem('ref');
        if (ref) {
          booking.ref = ref;
        }

        addBooking(booking)
        .then(response => {
            const bookingCode = response?.data?.code;
            if (bookingCode) {
                dispatch(setBookingCode(response.data.code));
            }
        })
        .then((error) => console.log(error))
      };

      const handleFormSumbmit = (e) => {
          if (formRef.current && !formRef.current.checkValidity()) {
            e.preventDefault();
            formRef.current.reportValidity();
          } else {
            const guestDetails = {
                name: nameInputRef.current.value,
                email: emailInputRef.current.value,
                phone: phoneInputRef.current.value,
                additionalRequest: commentInputRef.current.value,
              };
            window.fbq('track', 'Lead');
            dispatch(setGuestDetails(guestDetails));
            createBooking(guestDetails);
            
            nextStep();
          }
      };

      return (
        <PageContainer>
            <CheckDatesContainer>
              { selectedDates && <CheckDates linkText={TEXTS.formPages.page2.changeInformationText} dates={selectedDates} linkHandler={prevStep}/>}
            </CheckDatesContainer>
            <GuestDetailsFormContainer>
                <SecondHeadingContainer>
                    {TEXTS.formPages.page2.guestDetails}
                </ SecondHeadingContainer>
            </GuestDetailsFormContainer>
            <GuestDetailsInputContainer>
                <Form ref={formRef}>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Name*</Form.Label>
                        <Form.Control
                         ref={nameInputRef}
                         name="name"
                         type="text"
                         placeholder="James Bond"
                        //  value={name}
                        //  onChange={(e) => handleValueChange(e)}
                         required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email*</Form.Label>
                        <Form.Control
                            ref={emailInputRef}
                            name="email"
                            type="email"
                            placeholder="james.bond@gmail.com"
                            // value={email}
                            // onChange={(e) => handleValueChange(e)}
                            required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPhone">
                        <Form.Label>Phone*</Form.Label>
                        <PhoneInput
                            ref={phoneInputRef}
                            name='phone'
                            required
                            // value={phone}
                            // onChange={handlePhoneChange}
                            defaultMask="(...) ..-....-....."
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formComment">
                        <Form.Label>Additional Requests</Form.Label>
                        <Form.Control
                         ref={commentInputRef}   
                         name="comment"
                         as="textarea"
                         rows={3}
                         placeholder="I want to arrive by helicopter"
                        //  value={additionalRequest}
                        //  onChange={(e) => handleValueChange(e)}
                        />
                    </Form.Group>
                </Form>
            </GuestDetailsInputContainer>
            <Button id='secondButton' buttonText={TEXTS.formPages.page2.buttonText} clickHandler={handleFormSumbmit}/>
        </PageContainer>
    );
}

export default StepTwo
