import React from "react";
import Button from "../../../../../Components/Button";
import { TEXTS, COLORS } from "../../../../../Constants/variables";

import iconAirbnb from "../../../../../assets/images/form/buttons/airbnb.svg";
import iconBooking from "../../../../../assets/images/form/buttons/booking.svg";
import iconCard from "../../../../../assets/images/form/buttons/card.svg";
import iconPaypal from "../../../../../assets/images/form/buttons/paypal.svg";
import iconWhatsapp from "../../../../../assets/images/form/buttons/whatsapp.svg";
import { links } from "../../../../../Constants/settings";

import { handleExternalRedirect } from "../../../../../helpers/redirect";
import {
  formatDateWeb,
  getIntervalLength,
} from "../../../../../helpers/dateHelpers";

const getAirBnbLink = ({ bookingRange }) => `${
  links.airbnb
}checkin=${formatDateWeb(bookingRange.startDate)}&
numberOfGuests=1&
numberOfAdults=1&
checkout=${formatDateWeb(bookingRange.endDate)}&
productId=1016472629029495038&
isWorkTrip=false&
numberOfChildren=0&
numberOfInfants=0&
numberOfPets=0`;

const getBookingcomLink = ({ bookingRange }) => `${
  links.booking
}hotel_id=9130825&
room1=A%2CA&
stage=1&
checkin=${formatDateWeb(bookingRange.startDate)}&
interval=${getIntervalLength(bookingRange)}&
from_source=hotel&`;

export const AirbnbButton = (bookingRange) => (
  <Button
    id="airbnbButton"
    icon={iconAirbnb}
    buttonText={TEXTS.formPages.buttons.airbnb}
    background={COLORS.arbnbBtn}
    clickHandler={() => handleExternalRedirect(getAirBnbLink(bookingRange))}
  />
);
export const BookingcomButton = (bookingRange) => (
  <Button
    id="bookingcomButton"
    icon={iconBooking}
    buttonText={TEXTS.formPages.buttons.bookingcom}
    background={COLORS.bookingBtn}
    clickHandler={() => handleExternalRedirect(getBookingcomLink(bookingRange))}
  />
);
export const CreditCardButton = ({ clickHandler }) => (
  <Button
    id="creditCardButton"
    icon={iconCard}
    buttonText={TEXTS.formPages.buttons.card}
    clickHandler={clickHandler}
  />
);
export const PaypalButton = () => (
  <Button
    id="paypalButton"
    icon={iconPaypal}
    buttonText={TEXTS.formPages.buttons.paypal}
    clickHandler={() => handleExternalRedirect(links.paypal)}
  />
);
export const CallManagerButton = ({ booking }) => {
  const text = booking
    ? `Booking code: ${booking.bookingCode}
Check In: ${new Date(booking.range.startDate).toLocaleDateString()}
Check Out: ${new Date(booking.range.endDate).toLocaleDateString()}
${
  booking.additionalRequest
    ? `Additional Request: ${booking.additionalRequest}`
    : ""
}`
    : "";
  return (
    <Button
      id="callManagerButton"
      icon={iconWhatsapp}
      buttonText={TEXTS.formPages.buttons.manager}
      background={COLORS.whatsapp}
      // color={COLORS.grayRadio}
      // borderColor={COLORS.grayRadio}
      clickHandler={() =>
        handleExternalRedirect(
          `${links.whatsapp}?text=${encodeURIComponent(text)}`
        )
      }
    />
  );
};
