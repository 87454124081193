import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {PageContainer, SecondHeadingContainer } from './styles/stepOne.js';
import CheckDetailsWrapper from './components/CheckDetailsWrapper/index.js';
import CheckDates from './components/CheckDates/index.js';
import CarouselSlider from '../../../Components/Carousel/index.js';
import TagCloud from './components/TagCloud/index.js';
import PaymentInfo from './components/PaymentInfo/index.js';
import Button from '../../../Components/Button/index.js';

import { TEXTS } from '../../../Constants/variables';

const ReservationInfoContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 30px;
width: 100%;
`;

const ReservationDetailsContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const ReservationDetailRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: left;
`;

const ReservationDetailName = styled.div`
`;

const ReservationDetailValue = styled.div`
margin-left: 5px;
`;

const PhotoCarouselContainer = styled.div`
width: 100%;
height: auto;
`;

const TagCloudContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
`;

const ButtonContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 65%;
`;

const StepThree = ({nextStep, prevStep}) => {

    const { name, email, phone } = useSelector(state => state.booking.guestDetails);
    const { range, bookingCode, price } = useSelector(state => state.booking);
    
    return (
        <PageContainer>
            <ReservationInfoContainer>
                <SecondHeadingContainer style={{ margin: 0 }}>{TEXTS.formPages.page3.headingTop}</SecondHeadingContainer>
                <CheckDetailsWrapper linkText={TEXTS.formPages.page3.changeInformationText} linkHandler={prevStep}>
                    <ReservationDetailsContainer>
                        <ReservationDetailRow>
                            <ReservationDetailName>Name: </ReservationDetailName>
                            <ReservationDetailValue>{name}</ReservationDetailValue>
                        </ReservationDetailRow>
                        <ReservationDetailRow>
                            <ReservationDetailName>Email: </ReservationDetailName>
                            <ReservationDetailValue>{email}</ReservationDetailValue>
                        </ReservationDetailRow>
                        <ReservationDetailRow>
                            <ReservationDetailName>Phone: </ReservationDetailName>
                            <ReservationDetailValue>{phone}</ReservationDetailValue>
                        </ReservationDetailRow>
                    </ReservationDetailsContainer>
                </CheckDetailsWrapper>
                <CheckDates linkText={TEXTS.formPages.page3.changeInformationText} dates={range} bookingCode={bookingCode}/>
            <PhotoCarouselContainer>
            <CarouselSlider />
            </PhotoCarouselContainer>
            <TagCloudContainer>
                <TagCloud />
            </TagCloudContainer>
                <PaymentInfo price={price} />
                <ButtonContainer>
                    <Button id='thirdButton' buttonText={TEXTS.formPages.page4.buttonText} clickHandler={nextStep} />
                </ButtonContainer>
            </ReservationInfoContainer>
        </PageContainer>
    );
}

export default StepThree