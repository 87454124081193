import React from 'react';
import styled from 'styled-components';
import FormWizard from '../../Components/FormWizard';
import steps from './Steps';

const BookingFormContainer = styled.div`
// display: flex;
// flex-direction: column;
// align-items: center;
// justify-content: center;
margin: 20px 5px;
width: 500px;
@media (max-width: 420px) {
    width: 100%;
    padding: 0 10px;
`;

const BookingForm = () => {

    return (
        <BookingFormContainer>
             <FormWizard steps={steps} />
        </BookingFormContainer>
    )
}

export default BookingForm;