import React from "react";
import styled from "styled-components";

import Tag from '../../../../../Components/Tag';

import {tagData} from '../tags.js';

const TagCloudContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 10px;
flex-wrap: wrap;
`;

const TagCloud = () => {

    return (
        <TagCloudContainer>
            {Object.values(tagData).map((tag) => <Tag key={`proprty_tag_${tag.text}`} icon={tag.icon} text={tag.text} />)}
        </TagCloudContainer>
    )
};

export default TagCloud;