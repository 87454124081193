import React, {useState} from "react";
import styled from "styled-components";

import image1 from '../../assets/images/form/villaPhotos/1.JPG';
import image2 from '../../assets/images/form/villaPhotos/2.JPG';
import image3 from '../../assets/images/form/villaPhotos/3.JPG';
import image4 from '../../assets/images/form/villaPhotos/4.JPG';
import image5 from '../../assets/images/form/villaPhotos/5.JPG';
import image6 from '../../assets/images/form/villaPhotos/6.JPG';
import image7 from '../../assets/images/form/villaPhotos/7.JPG';
import image8 from '../../assets/images/form/villaPhotos/8.JPG';
import image9 from '../../assets/images/form/villaPhotos/9.JPG';
import image10 from '../../assets/images/form/villaPhotos/10.JPG';
  
import {ReactComponent as LeftArrow}  from '../../assets/images/form/left_arrow.svg';
import {ReactComponent as RightArrow} from '../../assets/images/form/right_arrow.svg';

import { COLORS } from "../../Constants/variables";

//Set images here
const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
  ];

const SliderImageContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  height: min-content;
`;

const SliderImage = styled.div`
background-image: url(${({ $background }) => $background});
background-repeat: no-repeat;
background-size: contain;
background-position: center;
width: 100%;
height: 300px;
`;

const ArrowConatiner = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    heigh: 60px;
    width: 40px;
    left: ${({ $position }) => $position === 'left' ? '0' : 'auto'};
    right: ${({ $position }) => $position === 'right' ? '0' : 'auto'};
    path {
        fill: ${COLORS.grayRadio};
    }
    &:hover {
        path {
            transform: translate(${({ $position }) => $position === 'left' ? '-5px' : '5px'});
        }
    }
`;

const DotsConatiner = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    bottom: 10px;
    .dot_index_${({ $activeIndex }) => $activeIndex } {
        background-color: ${COLORS.primaryWhite};
    }
`;

const Dot = styled.div`
border-radius: 100%;
height: 15px;
width: 15px;
border: 1px ${COLORS.grayRadio} solid;
background-color: ${({ active }) => active ? COLORS.primaryWhite : COLORS.grayRadio};
transform: scale( ${({ active }) => active ? '2' : '1'});
`;

const CarouselSlider = () => {
    
    const [currImage, setCurrImage] = useState(0);

    const getNext = (length) => (currIndex, direction) => {
        if (direction === 'next') return currIndex < length - 1 ? currIndex + 1 : 0;
        if (direction === 'prev') return currIndex === 0 ? length - 1 : currIndex - 1;
    };
    const getNextImage = getNext(images.length);
    const handleArrowClick = (direction) => setCurrImage(getNextImage(currImage, direction));

    return (
        <SliderImageContainer>
            <ArrowConatiner $position='left' onClick={() => handleArrowClick('prev')}>
              < LeftArrow />
            </ArrowConatiner>
            <SliderImage $background={images[currImage]} />
            <ArrowConatiner $position='right' onClick={() => handleArrowClick('next')}>
             < RightArrow />
            </ArrowConatiner>
            <DotsConatiner $activeIndex={currImage}>
                {images.map((image, index) => <Dot
                 className={`dot_index_${index}`}
                 key={`dot_key_${index}`}
                 />)}
            </DotsConatiner>
        </SliderImageContainer>
    )
};

export default CarouselSlider;