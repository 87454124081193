import { configureStore } from '@reduxjs/toolkit';
// import { combineSlices } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import { bookingApi } from '../api';
import { logger } from 'redux-logger';
import datesSlice from './bookingSlice';
import settingsSlince from './settingsSlince';

// export const rootReducer = combineSlices(bookingApi, datesSlice)

export const store = configureStore({
  reducer: {
    [bookingApi.reducerPath]: bookingApi.reducer,
    'booking': datesSlice,
    'settings': settingsSlince,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => process.env.NODE_ENV === 'development' ? getDefaultMiddleware().concat(bookingApi.middleware).concat(logger) :
    getDefaultMiddleware().concat(bookingApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);