import React from 'react';
import styled from 'styled-components';

import { COLORS } from '../../../../../Constants/variables';


const TextMarkBlockContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: center;
background-color: ${COLORS.graySecondary};
padding: .4em 2em;
margin: 10px 0;
border-radius: 5px;
`;

const TextMarkBlock = ({children}) => {
    return (
        <TextMarkBlockContainer>
        {children}
    </TextMarkBlockContainer>
    );
}

export default TextMarkBlock;