export const TYPOGRAPHY = {
    primaryFontType: 'Manrope',
    fontSmmall: '14px',
    headingLargeSize: '36px',
    topHeading: {
        topHeadingFontSize: '24px',
        topHeadingFontWeight: 700,
        topHeadingLineHeight: '20px',
    }
};

export const COLORS = {
    primaryWhite: '#FFFFFF',
    primaryFont: '#000000',
    secondaryFont: '#4E5D6D',
    backgroundBrown: '#8C7163',
    primaryButtonBackground: '#000000',
    graySecondary: '#EDF0F5',
    grayRadio: '#889397',
    brownSelection: '#889397',
    darkGraySelection: '#515659',
    tagColor: '#4E5D6D',
    primaryGold: '#8C7163',
    primaryBackground: '#FFFFFF',
    baseBackground: "#F0DDC6",
    arbnbBtn: '#FF385C',
    bookingBtn: '#003487',
    transparent: 'transparent',
    whatsapp: '#075E54',
  };

  export const TEXTS = {
    formPages: {
        step: 'step',
        loadingText: 'Loading available dates...',
        errorLoading: 'Error loading dates',
        errorProceedingInvoice: 'Error creating invoice',
        page1: {
            headingTopPt1:'Book',
            headingTopPt2:'Private Jet Villa',
            descriptionPt1:'once-in-a-lifetime stay at our exclusive airplane villa on a cliff, offering luxury and spectacular views! ',
            descriptionPt2Bold: '',
            descriptionPt3: '',
            CalendarHeading:'Choose Dates',
            PriceText:'Total price:',
            ButtonText: 'Continue',
        },
        page2: {
            headingTop:'Reservation Details',
            bookingCodeText: 'Booking code: ',
            checkInText: 'Check in: ',
            checkOutText: 'Check out: ',
            changeInformationText: 'Change information',
            calendarHeading:'Choose Dates',
            guestDetails:'Guest details',
            buttonText: 'Continue',
        },
        page3: {
            headingTop:'Confirmation',
            changeInformationText: 'Change information',
            details: 'Reservation Details',
            bookingCodeText: 'Booking code: ',
            checkInText: 'Check in: ',
            checkOutText: 'Check out: ',
            paymentText:'Payment',
            refund:'100% refund if cancelled before 28 days prior check-in date.',
            payMethod: 'Payment Method',
            buttonText: 'Continue',
        },
        page4: {
            refund:'100% refund if cancelled before 28 days prior check-in date.',
            payMethod: 'Pay Method',
            card: 'Pay by card',
            buttonText: 'Pay Now',
        },
        tags: {
            bedrooms: '2 x Bedrooms',
            beds: '2 x King size beds',
            kitchen: 'Kitchen',
            bathrooms: '2 x Bathrooms',
            toilets: '2 x Toilets',
            jacuzzi: 'Jacuzzi',
            sunchairs: 'Sun Loungers',
            helipad: 'Helipad',
            fire: 'Bonfire pit',
            pool: 'Swimming pool',
        },
        buttons: {
            airbnb: 'Airbnb',
            bookingcom: 'Booking.com',
            paypal: 'PayPal',
            card: 'Pay by card',
            manager: 'Any questions?',
        },
        confirmationPage: {
            name: 'Name',
            email: 'Email',
            phone: 'Phone',
            additionalRequest: 'Special Requests'
        }
    },
  }
