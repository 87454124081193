import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BookingForm from './Pages/BookingForm';
import BookingConfirmation from './Pages/BookingConfirmation';
// import VerticalWrapper from './Components/VerticalWrapper';
// import DatesPage from './Pages/DatesPage';
// import DetailsPage from './Pages/DetailsPage';
// import ConfirmationPage from './Pages/ConfirmationPage';
// import InvoicePage from './Pages/InvocePage';

// Vertical wrapper is for old page compatibility

function App() {
  return (
    <Routes>
    {/* <Route path='/' element={<VerticalWrapper><DatesPage /></ VerticalWrapper>} />
    <Route path='/details' element={<VerticalWrapper><DetailsPage /></ VerticalWrapper>} />
    <Route path='/confirmation' element={<VerticalWrapper><ConfirmationPage /></ VerticalWrapper>} />
    <Route path='/invoice' element={<VerticalWrapper><InvoicePage /></ VerticalWrapper>} />  */}
    <Route path='/' element={<BookingForm />} />
    <Route path='/confirmation' element={<BookingConfirmation />} />
  </Routes>
  );
}

export default App;