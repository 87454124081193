import React, {useState} from 'react';
import BackgroundWrapper from './BackgroundWrapper';
import { FormWizardConatainer, FormWizrdHeader, FormWizrdContentContainer, PageCounter, CloseCircle, StepText, StepsLeft } from './styles.js';
import { handleExternalRedirect } from '../../helpers/redirect.js';
import { links } from '../../Constants/settings.js';

import { TEXTS } from '../../Constants/variables';

import styled from 'styled-components';

const StepControl = styled.a`
    color: red;
    pointer-events: all;
    cursor: pointer;
    &:hover: {
        transform: scale(1.2);
    }
`;

const FormWizard = ({steps}) => {

    const stepNumber = Object.keys(steps).length;
    const [currentStep, setCurrentStep] = useState(0);

    const getNextStep = () => currentStep < (stepNumber - 1) ? setCurrentStep(currentStep + 1) : null;
    const getPrevStep = () => currentStep > 0 ? setCurrentStep(currentStep - 1) : null;

    const CurrentComponent = steps[currentStep].contents;
    
    return (
        <FormWizardConatainer>
            <FormWizrdHeader >
                {stepNumber >= 2 &&
                    <PageCounter>
                        <StepText>{TEXTS.formPages.step}</StepText>
                        <StepsLeft>{`${currentStep + 1}/${stepNumber}`}</StepsLeft>
                        {process.env.NODE_ENV === 'development' && (
                            <>
                                <StepControl onClick={getPrevStep}>🢀</StepControl>
                                <StepControl onClick={getNextStep}>🢂</StepControl>
                            </>
                        )}
                    </PageCounter>
                }
            <CloseCircle onClick={() => handleExternalRedirect(links.mainLanding)}/>
            </FormWizrdHeader>
            <FormWizrdContentContainer>
            <BackgroundWrapper background={steps[currentStep].background}>
               {<CurrentComponent nextStep={getNextStep} prevStep={getPrevStep}/>}
            </BackgroundWrapper>
            </FormWizrdContentContainer>
        </FormWizardConatainer>
    )
}

export default FormWizard;